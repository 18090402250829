<template>
  <div class="loadingWra">
    <div class="loading">
      <div>
        <div class="c1"></div>
        <div class="c2"></div>
        <div class="c3"></div>
        <div class="c4"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { defaultValue } from "../defaultValue.js";
// eslint-disable-next-line no-unused-vars
import { fileProcess } from "@/utils/imageHandler";
// eslint-disable-next-line no-unused-vars
import { mergeDeep, rotateImage, imageCompress } from "@/utils/index";
export default {
  data() {
    return {};
  },
  created() {
      this.getResultDetails(localStorage.getItem('uuid'));
  },
  methods: {
    // 颜值分析
    getResultDetails(uuid) {
      this.$api.skinTest
        .getFacialfeatures({
          uuid: uuid
        })
        .then(res => {
          if (res.data.success) {
            let formatResult = mergeDeep(res.data.data.result, defaultValue);
            this.$store.state.result = {
              ...res.data.data,
              result: formatResult
            };
            this.$store.state.imgSrc = res.data.data.imageUrl;
            this.$router.push({
              path: "/animate"
            });
          }
        });
    }
  }
};
</script>
<style lang="less" scoped>
.loading {
  width: 100%;
  height: 100%;
  background: #333333;
  opacity: 0.9;
  position: fixed;
}
.loading > div {
  width: 60px;
  height: 60px;
  position: absolute;
  left: 50%;
  margin-left: -30px;
  top: 50%;
  margin-top: -30px;
}
.loading > div > div {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  background: #ffffff;
  top: 10px;
  left: 10px;
  transform-origin: 20px 20px;
  border-radius: 8px;
  animation: spin-a 1.5s infinite cubic-bezier(0.5, 0, 0.5, 1);
}

.loading > div > .c2 {
  top: 10px;
  left: auto;
  right: 10px;
  transform-origin: -4px 20px;
  animation: spin-b 1.5s infinite cubic-bezier(0.5, 0, 0.5, 1);
}
.loading > div > .c3 {
  top: auto;
  left: auto;
  right: 10px;
  bottom: 10px;
  transform-origin: -4px -4px;
  animation: spin-c 1.5s infinite cubic-bezier(0.5, 0, 0.5, 1);
}
.loading > div > .c4 {
  top: auto;
  bottom: 10px;
  transform-origin: 20px -4px;
  animation: spin-d 1.5s infinite cubic-bezier(0.5, 0, 0.5, 1);
}

@keyframes spin-a {
  0%   { transform: rotate(90deg); }
  0%  { transform: rotate(90deg); }
  50%  { transform: rotate(180deg); }
  75%  { transform: rotate(270deg); }
  100% { transform: rotate(360deg); }
}
@keyframes spin-b {
  0%   { transform: rotate(90deg); }
  25%  { transform: rotate(90deg); }
  25%  { transform: rotate(180deg); }
  75%  { transform: rotate(270deg); }
  100% { transform: rotate(360deg); }
}
@keyframes spin-c {
  0%   { transform: rotate(90deg); }
  25%  { transform: rotate(90deg); }
  50%  { transform: rotate(180deg); }
  50%  { transform: rotate(270deg); }
  100% { transform: rotate(360deg); }
}
@keyframes spin-d {
  0%   { transform: rotate(90deg); }
  25%  { transform: rotate(90deg); }
  50%  { transform: rotate(180deg); }
  75%  { transform: rotate(270deg); }
  75% { transform: rotate(360deg); }
  100% { transform: rotate(360deg); }
}


.loading > span {
  width: 100px;
  height: 30px;
  position: absolute;
  left: 50%;
  margin-left: -50px;
  top: 50%;
  margin-top: 30px;
  color: #ff8c00;
  font-size: 12px;
  text-align: center;
}

.content { padding: 1.5em; }
</style>
