export const defaultValue = {
  eyes: {
    angulus_oculi_medialis: 72,
    eyes_type: "normal_eyes",
    eye_height: 7.02,
    eye_width: 22.08
  },
  mouth: {
    mouth_height: 15.13,
    mouth_width: 40.08,
    lip_thickness: 6.15,
    mouth_type: "smile_lip",
    angulus_oris: 100.83
  },
  golden_triangle: 60,
  five_eyes: {
    eyes_ratio: "0.8:0.97:1.38:1:0.97",
    one_eye: {
      righteye_empty_result: "righteye_empty_normal",
      righteye_empty_length: 20.79,
      righteye_empty_ratio: 0.8
    },
    five_eye: {
      lefteye_empty_result: "lefteye_empty_normal",
      lefteye_empty_length: 25.23,
      lefteye_empty_ratio: 0.97
    },
    lefteye: 25.94,
    three_eye: {
      eyein_ratio: 1.38,
      eyein_result: "eyein_normal",
      eyein_length: 35.9
    },
    righteye: 25.23
  },
  jaw: {
    jaw_type: "flat_jaw",
    jaw_length: 28.44,
    jaw_width: 46.07,
    jaw_angle: 155.15
  },
  three_parts: {
    one_part: {
      faceup_ratio: 0.34,
      faceup_result: "faceup_normal",
      faceup_length: 62.05
    },
    three_part: {
      facedown_length: 62.04,
      facedown_result: "facedown_normal",
      facedown_ratio: 0.33
    },
    two_part: {
      facemid_length: 60.25,
      facemid_ratio: 0.33,
      facemid_result: "facemid_normal"
    },
    parts_ratio: "0.34:0.33:0.33"
  },
  nose: { nose_width: 31.63, nose_type: "normal_nose" },
  eyebrow: {
    eyebrow_type: "bushy_eyebrows",
    brow_height: 4.84,
    brow_camber_angle: 1.08,
    brow_uptrend_angle: 12,
    brow_width: 31.85,
    brow_thick: 4.46
  },
  face: {
    face_length: 106.04,
    E: 151.15,
    mandible_length: 86.99,
    tempus_length: 106.04,
    face_type: "normal_face",
    zygoma_length: 109.36
  }
};
